<template>
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        Müşteri Bilgileri
      </div>
    </template>

    <div class="row">
      <!-- türü -->
      <div class="col-6">
        <div class="form-group">
          <label for="turu">Türü * </label>
          <select
            class="form-control"
            v-model="type"
            id="exampleFormControlSelect1"
          >
            <option selected>Seçiniz...</option>
            <option value="1">Bireysel</option>
            <option value="2">Ticari</option>
          </select>
        </div>
      </div>
      <!-- tc -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="tc">TC Kimlik No</label>
          <input
            class="form-control"
            v-model="tck"
            type="text"
            id="emlakKodu"
            required
          />
        </b-form-group>
      </div>
      <!-- tc -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="adi">Adı</label>
          <input
            class="form-control"
            v-model="first_name"
            type="text"
            id="adi"
            required
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="soyad">Soyadı</label>
          <input
            class="form-control"
            v-model="last_name"
            type="text"
            id="soyad"
            required
          />
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group class="add-style">
          <label for="unvan">Ünvanı</label>
          <input
            class="form-control"
            v-model="title"
            type="text"
            id="unvan"
            required
          />
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group class="add-style">
          <label for="vergiNo">Vergi Numarası</label>
          <input
            class="form-control"
            v-model="tax_number"
            type="text"
            id="vergiNo"
            required
          />
        </b-form-group>
      </div>
      <!-- il -->
      <div class="col-6">
        <div class="form-group">
          <MultiSelect
            :key="counties.length"
            v-model="providence"
            :options="counties"
            :multiple="false"
            :defaultValue="city ? city : ''"
            label="İl"
            placeholder="İl Seçiniz"
            id="il"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <MultiSelect
            v-model="district"
            :options="districts"
            :defaultValue="district ? district : ''"
            :multiple="false"
            label="İlçe"
            placeholder="İlçe Seçiniz"
            id="ilce"
          />
        </div>
      </div>
      <div class="col-3">
        <b-form-group class="add-style">
          <label for="telNo">Gsm</label>
          <input
            class="form-control"
            v-model="phone"
            type="phone"
            id="telNo"
            required
          />
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group class="add-style">
          <label for="yurtDisiTel">Yurt Dışı Tel No</label>
          <input
            class="form-control"
            type="phone"
            v-model="foreign_phone"
            id="yurtDisiTel"
            required
          />
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group class="add-style">
          <label for="telefon1">Telefon 1</label>
          <input
            class="form-control"
            type="phone"
            v-model="phone_1"
            id="telefon1"
            required
          />
        </b-form-group>
      </div>
      <div class="col-3">
        <b-form-group class="add-style">
          <label for="telefon2">Telefon 2</label>
          <input
            class="form-control"
            type="phone"
            v-model="phone_2"
            id="telefon2"
            required
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="mail">E-posta 1</label>
          <input
            class="form-control"
            type="mail"
            v-model="e_mail"
            id="mail"
            required
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="mail2">E-Posta 2</label>
          <input
            class="form-control"
            type="mail"
            id="mail2"
            v-model="e_mail_2"
            required
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="meslek">Meslek</label>
          <input
            class="form-control"
            type="text"
            id="meslek"
            v-model="job"
            required
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="ref">Referans</label>
          <input
            class="form-control"
            type="text"
            id="ref"
            v-model="reference"
            required
          />
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group class="add-style">
          <label for="kanal">Ulaştığı Kanal</label>
          <input
            class="form-control"
            type="text"
            id="kanal"
            v-model="reaching_channel"
            required
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="fiyat">İlgilendiği Fiyat Aralığı</label>
          <div class="d-flex">
            <input
              class="form-control"
              type="text"
              id="fiyat"
              required
              placeholder="Min"
              v-model="price_range[0]"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
            <input
              class="form-control"
              placeholder="Max"
              type="text"
              id="fiyat"
              required
              v-model="price_range[1]"
              style="
                border-left-width: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              "
            />
          </div>
        </b-form-group>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="turu">Kullanıcı Listesi * </label>
          <select
            v-model="user_list"
            class="form-control"
            id="exampleFormControlSelect1"
          >
            <option selected>Seçiniz...</option>
            <option value="1">Beyaz Liste</option>
            <option value="2">Kara Liste</option>
          </select>
        </div>
      </div>

      <div class="col-6">
        <button
          class="bg-light p-2 d-flex align-items-center rounded-sm row mt-4 mx-1"
          @click="addLocation(yeniEkle)"
        >
          <i class="flaticon2-plus mr-2"></i>Yeni Ekle
        </button>
      </div>

      <div
        class="col-12"
        v-for="(location, i) in locations"
        :id="'yeni-' + i"
        :key="i"
      >
        <div class="row">
          <div
            class="col-1"
            style="
              max-width: 8.33333333%;
              justify-content: center;
              align-items: center;
              display: flex;
            "
          >
            <b-button variant="light" @click="deleteRow(i)">
              <i class="flaticon2-trash p-0"></i>
            </b-button>
          </div>
          <!-- il -->
          <div class="col mt-4">
            <div class="form-group">
              <MultiSelect
                :key="counties.length"
                v-model="location.il"
                :options="counties"
                :multiple="false"
                :defaultValue="location"
                label="İl"
                placeholder="İl Seçiniz"
                :id="'il-' + i"
                @input="onSubProvidenceChange(i)"
              />
            </div>
          </div>
          <!-- ilçe -->
          <div class="col mt-4">
            <div class="form-group">
              <MultiSelect
                :key="districts.length"
                v-model="location.ilce"
                :options="location.districts"
                :multiple="false"
                label="İl"
                placeholder="İl Seçiniz"
                :id="'ilce-' + i"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5">
        <div class="form-group mt-4">
          <label for="yetkiBelgeNo">İlgilendiği Taşınmaz Türü</label>
          <select
            v-model="torein"
            class="form-control"
            id="exampleFormControlSelect1"
          >
            <option selected>Seçiniz</option>
            <option value="1">Konut</option>
            <option value="2">Ticari</option>
            <option value="3">Arsa</option>
            <option value="4">Tarla</option>
          </select>
        </div>
      </div>

      <div class="col-12">
        <b-form-group class="add-style">
          <label for="fiyat">Açıklama</label>
          <div class="d-flex">
            <textarea
              v-model="explanation"
              name=""
              id=""
              class="form-control"
              rows="3"
            ></textarea>
          </div>
        </b-form-group>
      </div>
    </div>

    <button class="saveButton mx-auto mt-5" @click="addCustomer">Kaydet</button>
  </Card>
</template>

<script>
import { COUNTIES, DISTRICTS } from "@/core/services/store/counties.module.js";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      row: [],
      districts: [],
      counties: [],
      rows: [{}],
      yeniEkle: 0,
      providence: "",
      district: "",
      locations: [],
      type: "",
      tck: "",
      first_name: "",
      last_name: "",
      title: "",
      tax_number: "",
      city: "",
      address: "",
      phone: "",
      foreign_phone: "",
      phone_1: "",
      phone_2: "",
      e_mail: "",
      e_mail_2: "",
      job: "",
      reference: "",
      reaching_channel: "",
      price_range: [],
      user_list: "",
      explanation: "",
      add_new: "",
      torein: "",
      customer_id: "",
      is_update: false,
    };
  },
  created() {
    this.$store.dispatch(COUNTIES);
    if (this.$route.params.id) {
      this.$ApiService
        .get(
          `admin/customers?user_token=${this.myUser}&customers_id=${this.$route.params.id}`
        )
        .then((response) => {
          const data = response.data.response.data;
          this.type = data.type;
          this.tck = data.tck;
          this.first_name = data.first_name;
          this.last_name = data.last_name;
          this.title = data.title;
          this.tax_number = data.tax_number;
          this.address = data.address;
          this.phone = data.phone;
          this.foreign_phone = data.foreign_phone;
          this.phone_1 = data.phone_1;
          this.phone_2 = data.phone_2;
          this.e_mail = data.e_mail;
          this.e_mail_2 = data.e_mail_2;
          this.job = data.job;
          this.reference = data.reference;
          this.reaching_channel = data.reaching_channel;
          this.price_range = data.price_range.split(",");
          this.user_list = data.user_list;
          this.explanation = data.explanation;
          this.torein = data.torein;
          const countySelect = this.counties.find(
            (il) => il.text === data.city
          );
          this.city = countySelect;
          this.district = data.address;
          this.addNewUpdate(data.add_new);
        });
    }
  },
  methods: {
    countyChange(e) {
      let filteredData = this.counties.filter(
        (county) => county.plaka == e.target.value
      );
      this.districts = filteredData[0].ilceleri;
    },
    addLocation() {
      this.yeniEkle = this.yeniEkle + 1;
      this.locations.push({
        il: "",
        ilce: "",
        districts: [],
        id: this.yeniEkle,
      });
    },
    deleteRow(index) {
      /* let row = document.getElementById("yeni-" + i);
      row.remove(); */
      this.locations = this.locations.slice(index - 1, 1);
    },
    setCountiesSelectValues(county) {
      const newCounties = county.map((index, key) => {
        return { text: index.il_adi, value: index.il_id };
      });
      this.counties = newCounties;
    },
    setDistrictsSelectValues(districts) {
      const setDistrictsSelect = districts.map((index, key) => {
        return { text: index.ilce_adi, value: index.ilce_id };
      });
      return setDistrictsSelect;
    },
    onProvinceChange() {
      if (this.providence) {
        this.$store
          .dispatch(DISTRICTS, this.providence.value)
          .then((districts) => {
            const newDistricts = this.setDistrictsSelectValues(districts);
            this.districts = newDistricts;
          });
      } else {
        this.districts = [];
      }
    },
    onSubProvidenceChange(i) {
      const selectedProvince = this.locations[i].il.value;

      this.$store.dispatch(DISTRICTS, selectedProvince).then((districts) => {
        const newDistricts = this.setDistrictsSelectValues(districts);
        this.locations[i].districts = newDistricts;
      });
    },
    addCustomer() {
      const locationsArray = this.locations.map((item) => {
        return {
          il_adi: item.il.text,
          il_id: item.il.value,
          ilce_adi: item.ilce.text,
          ilce_id: item.ilce.value,
        };
      });

      let data = {
        user_token: this.myUser,
        type: this.type,
        tck: this.tck,
        first_name: this.first_name,
        last_name: this.last_name,
        title: this.title,
        tax_number: this.tax_number,
        city: this.providence.text,
        address: this.district.text,
        phone: this.phone,
        foreign_phone: this.foreign_phone,
        phone_1: this.phone_1,
        phone_2: this.phone_2,
        e_mail: this.e_mail,
        e_mail_2: this.e_mail_2,
        job: this.job,
        reference: this.reference,
        reaching_channel: this.reaching_channel,
        price_range: this.price_range,
        user_list: this.user_list,
        explanation: this.explanation,
        torein: this.torein,
        explanation: this.explanation,
      };
      const formDataSend = new FormData();
      for (const key in data) {
        formDataSend.append(key, data[key]);
      }
      locationsArray.forEach((add_new, index) => {
        formDataSend.append(`add_new[${index}][il_adi]`, add_new.il_adi);
        formDataSend.append(`add_new[${index}][ilce_adi]`, add_new.ilce_adi);
        formDataSend.append(`add_new[${index}][il_id]`, add_new.il_id);
        formDataSend.append(`add_new[${index}][ilce_id]`, add_new.ilce_id);
      });
      this.$ApiService
        .post("admin/customers", formDataSend)
        .then((response) => {
          if (response.data.status.code === 200) {
            Swal.fire({
              title: "Yeni kayıt eklensin mi?",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Evet",
              cancelButtonText: "Hayır",
              denyButtonText: `Hayır`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                Swal.fire("Başarılı bir şekilde kaydedildi!", "", "success");
                this.$router.push("/manage/customer");
              }
            });
          } else {
            this.$generateNotification(
              this,
              "error",
              "Kayıt eklenirken bir hata ile karşılaşıldı."
            );
          }
        })
        .catch((err) => {
          this.$generateNotification(
            this,
            "error",
            "Kayıt eklenirken bir hata ile karşılaşıldı."
          );
        });
    },
    addNewUpdate(add_new) {
      add_new.map((newL, key) => {
        let district = this.$store
          .dispatch(DISTRICTS, newL.il_id)
          .then((districts) => {
            const newDistricts = this.setDistrictsSelectValues(districts);
            return newDistricts;
          });

        const locationArray = {
          il: { text: newL.il_adi, value: newL.il_id },
          ilce: { text: newL.ilce_adi, value: newL.ilce_id },
          id: this.yeniEkle++,
          districts: district,
        };

        this.locations.push(locationArray);
      });
    },
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.is_update = true;
      }
    },

    providence: "onProvinceChange",
    getCounties(newCounties) {
      if (newCounties.length > 0) {
        this.setCountiesSelectValues(newCounties);
      }
    },

    getDistricts(newDistricts) {
      if (newDistricts.length > 0) {
        if (this.$route.params.id && typeof this.district === "string") {
          const filtiredDistrict = this.getDistricts.find(
            (ilce) => ilce.ilce_adi === this.district
          );
          this.district = {
            text: filtiredDistrict.ilce_adi,
            value: filtiredDistrict.ilce_id,
          };
        }
      }
    },
  },
  computed: {
    ...mapGetters(["myUser", "getCounties", "getDistricts"]),
  },
};
</script>

<style scoped>
.saveButton {
  background-color: rgb(80, 184, 80);
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
}
.saveButton:hover {
  background-color: rgb(60, 151, 60);
  transition: 0.2s;
}
</style>
